define("@manager-web/grids/components/grid/card", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
    class="group flex w-full flex-col rounded-xl text-lg shadow-sm"
  >
    {{#if (has-block "header")}}
      <div
        class="flex w-full items-center gap-4 rounded-t-xl border border-b-2 border-gray-200 bg-white px-6"
      >
        {{yield to="header"}}
        {{#if (has-block "onHoverHeaderActions")}}
          <div
            class="invisible flex gap-4 py-4 group-focus-within:visible group-hover:visible"
          >
            {{yield to="onHoverHeaderActions"}}
          </div>
        {{/if}}
        {{#if (has-block "headerActions")}}
          <div class="flex gap-4 py-4">
            {{yield to="headerActions"}}
          </div>
        {{/if}}
      </div>
    {{/if}}
    <div
      class={{class-join
        "border-x border-gray-200 bg-white"
        (unless (has-block "footer") "rounded-b-xl border-b")
        (unless (has-block "header") "rounded-t-xl border-t")
      }}
    >
      {{#if @isLoading}}
        <div class="px-6 py-4">
          <LoadingSpinner />
        </div>
      {{else if @isEmpty}}
        <Grid::Card::Empty class="px-6 py-4" @title={{t "all.nothing_to_show"}} />
      {{else}}
        {{yield}}
      {{/if}}
    </div>
    {{#if (has-block "footer")}}
      <div class="w-full rounded-b-xl border border-gray-200 bg-white p-6">
        {{yield to="footer"}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "deSb5QTC",
    "block": "[[[11,0],[17,1],[24,0,\"group flex w-full flex-col rounded-xl text-lg shadow-sm\"],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[10,0],[14,0,\"flex w-full items-center gap-4 rounded-t-xl border border-b-2 border-gray-200 bg-white px-6\"],[12],[1,\"\\n      \"],[18,4,null],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"        \"],[10,0],[14,0,\"invisible flex gap-4 py-4 group-focus-within:visible group-hover:visible\"],[12],[1,\"\\n          \"],[18,5,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[48,[30,6]],[[[1,\"        \"],[10,0],[14,0,\"flex gap-4 py-4\"],[12],[1,\"\\n          \"],[18,6,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[15,0,[28,[37,4],[\"border-x border-gray-200 bg-white\",[52,[51,[48,[30,7]]],\"rounded-b-xl border-b\"],[52,[51,[48,[30,4]]],\"rounded-t-xl border-t\"]],null]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,0],[14,0,\"px-6 py-4\"],[12],[1,\"\\n        \"],[8,[39,6],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"      \"],[8,[39,7],[[24,0,\"px-6 py-4\"]],[[\"@title\"],[[28,[37,8],[\"all.nothing_to_show\"],null]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,8,null],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"    \"],[10,0],[14,0,\"w-full rounded-b-xl border border-gray-200 bg-white p-6\"],[12],[1,\"\\n      \"],[18,7,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"@isLoading\",\"@isEmpty\",\"&header\",\"&onHoverHeaderActions\",\"&headerActions\",\"&footer\",\"&default\"],false,[\"div\",\"if\",\"has-block\",\"yield\",\"class-join\",\"unless\",\"loading-spinner\",\"grid/card/empty\",\"t\"]]",
    "moduleName": "@manager-web/grids/components/grid/card.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});