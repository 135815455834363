define("forms/components/button-multiselect", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@glimmer/tracking", "@manager-web/common/decorators/arg-types", "prop-types", "@ember/template-factory"], function (_exports, _component, _object, _component2, _tracking, _argTypes, _propTypes, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class="button-multiselect twin-buttons">
    <IconButton
      @isActive={{this.isPopoverOpen}}
      @theme={{this.theme}}
      @showFocus={{false}}
      @iconSize="md"
    >
      <IlmIcon @icon="filter" />
      <IlmIcon @icon={{if this.isPopoverOpen "caret-up" "caret-down"}} />
      {{~#unless this.disabled}}
        <FloatinguiPopover
          @arrow={{true}}
          @hideOn="escapekey"
          @interactive={{true}}
          @lazyRender={{true}}
          @placement="bottom"
          @isShown={{this.isPopoverOpen}}
          @onChange={{fn (mut this.isPopoverOpen)}}
          @showOn="click focus"
          as |popover|
        >
          <PopoverContent @visible={{this.isPopoverOpen}} @popover={{popover}}>
            <div class="p-4">
              <FiltersMultiselect
                @options={{this.options}}
                @fieldName={{this.fieldName}}
                @label={{this.popoverLabel}}
                @group={{this.elementId}}
                @onChange={{this.onChange}}
                as |option|
              >
                {{yield option}}
              </FiltersMultiselect>
            </div>
          </PopoverContent>
        </FloatinguiPopover>
      {{/unless}}
    </IconButton>
    {{#if this.someSelected}}
      <IconButton
        @icon="cancel"
        @onClick={{this.clearFilters}}
        @theme="active clearfilters"
        @tooltip={{t "all.clear_filters"}}
        @iconSize="lg"
      />
    {{/if}}
  </div>
  */
  {
    "id": "fN+64xLX",
    "block": "[[[11,0],[17,1],[24,0,\"button-multiselect twin-buttons\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@isActive\",\"@theme\",\"@showFocus\",\"@iconSize\"],[[30,0,[\"isPopoverOpen\"]],[30,0,[\"theme\"]],false,\"md\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[\"filter\"]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[[52,[30,0,[\"isPopoverOpen\"]],\"caret-up\",\"caret-down\"]]],null],[41,[51,[30,0,[\"disabled\"]]],[[[1,\"      \"],[8,[39,5],null,[[\"@arrow\",\"@hideOn\",\"@interactive\",\"@lazyRender\",\"@placement\",\"@isShown\",\"@onChange\",\"@showOn\"],[true,\"escapekey\",true,true,\"bottom\",[30,0,[\"isPopoverOpen\"]],[28,[37,6],[[28,[37,7],[[30,0,[\"isPopoverOpen\"]]],null]],null],\"click focus\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,8],null,[[\"@visible\",\"@popover\"],[[30,0,[\"isPopoverOpen\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"p-4\"],[12],[1,\"\\n            \"],[8,[39,9],null,[[\"@options\",\"@fieldName\",\"@label\",\"@group\",\"@onChange\"],[[30,0,[\"options\"]],[30,0,[\"fieldName\"]],[30,0,[\"popoverLabel\"]],[30,0,[\"elementId\"]],[30,0,[\"onChange\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[18,4,[[30,3]]],[1,\"\\n            \"]],[3]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"someSelected\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@icon\",\"@onClick\",\"@theme\",\"@tooltip\",\"@iconSize\"],[\"cancel\",[30,0,[\"clearFilters\"]],\"active clearfilters\",[28,[37,11],[\"all.clear_filters\"],null],\"lg\"]],null],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"popover\",\"option\",\"&default\"],false,[\"div\",\"icon-button\",\"ilm-icon\",\"if\",\"unless\",\"floatingui-popover\",\"fn\",\"mut\",\"popover-content\",\"filters-multiselect\",\"yield\",\"t\"]]",
    "moduleName": "forms/components/button-multiselect.hbs",
    "isStrictMode": false
  });
  let ButtonMultiselectComponent = _exports.default = (_dec = (0, _argTypes.arg)(), _dec2 = (0, _argTypes.arg)(), _dec3 = (0, _argTypes.arg)(), _dec4 = (0, _argTypes.arg)(_propTypes.func), _dec5 = (0, _argTypes.arg)(_propTypes.func), _dec6 = (0, _argTypes.arg)(_propTypes.bool), _dec7 = (0, _argTypes.arg)(_propTypes.bool), _dec8 = (0, _argTypes.arg)(_propTypes.string), _dec9 = (0, _argTypes.arg)(_propTypes.string), (_class = class ButtonMultiselectComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "options", _descriptor, this);
      _initializerDefineProperty(this, "label", _descriptor2, this);
      _initializerDefineProperty(this, "popoverLabel", _descriptor3, this);
      _initializerDefineProperty(this, "onChange", _descriptor4, this);
      _initializerDefineProperty(this, "onClear", _descriptor5, this);
      _initializerDefineProperty(this, "clearOnEmpty", _descriptor6, this);
      _initializerDefineProperty(this, "disabled", _descriptor7, this);
      _initializerDefineProperty(this, "theme", _descriptor8, this);
      _initializerDefineProperty(this, "fieldName", _descriptor9, this);
      _initializerDefineProperty(this, "isPopoverOpen", _descriptor10, this);
    }
    // Computed ********************************

    get selected() {
      const {
        fieldName
      } = this;
      return this.options.filter(item => (0, _object.get)(item, fieldName));
    }
    get unselected() {
      const {
        fieldName
      } = this;
      return this.options.filter(item => (0, _object.get)(item, fieldName) === false);
    }
    get allSelected() {
      return this.unselected.length === 0;
    }
    get someSelected() {
      const nSelected = this.selected.length;
      return nSelected && !this.allSelected;
    }

    // Actions ********************************

    clearFilters() {
      if (typeof this.onClear === 'function') {
        this.onClear();
      } else {
        this.options.forEach(item => (0, _object.set)(item, this.fieldName, true));
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "options", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "popoverLabel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onClear", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "clearOnEmpty", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "fieldName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'isVisible';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isPopoverOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ButtonMultiselectComponent);
});