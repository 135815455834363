define("forms/components/filter-search", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/runloop", "@glimmer/tracking", "@manager-web/common/decorators/arg-types", "@ember/template-factory"], function (_exports, _component, _component2, _object, _runloop, _tracking, _argTypes, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class={{bem "filter-search" focused=this.isFocused open=this.isOpen}}>
  
    {{!-- <div class="forms-group">
      {{#if @searchOptions}}
        <FDropdown
          @options={{@searchOptions}}
          @selected={{@selectedSearchOption}}
          @matchTriggerWidth={{false}}
          @disabled={{or @isWorking @disabled}}
          @onChange={{@searchOptionChange}}
          as |option|
        >
          {{t option.translationKey}}
        </FDropdown>
      {{/if}} --}}
    <input
      placeholder={{@placeholder}}
      class="filter-search__input"
      name={{or @inputName "search"}}
      {{did-insert this.setupInput}}
      {{on "input" (pick "target.value" this.inputHandler)}}
      {{on "focus" (fn this.focusHandler true)}}
      {{on "blur" (fn this.focusHandler false)}}
      {{autofocus disabled=(not @autofocus)}}
    />
    {{#if @isWorking}}
      <div
        class="btn btn--icon"
        type="button"
        {{!-- {{on "click" this.focusInput}} --}}
      >
        <LoadingSpinner />
      </div>
    {{else if this.currentValue}}
      <IconButton
        @icon="cancel"
        @onClick={{this.clear}}
        @iconSize="lg"
        @showFocus={{false}}
        name="clear_search"
      />
    {{else}}
      <IconButton
        @icon="search"
        @onClick={{this.focusInput}}
        @iconSize="lg"
        @showFocus={{false}}
      />
    {{/if}}
    {{! </div> }}
  </label>
  */
  {
    "id": "3pwXO3Np",
    "block": "[[[10,\"label\"],[15,0,[28,[37,1],[\"filter-search\"],[[\"focused\",\"open\"],[[30,0,[\"isFocused\"]],[30,0,[\"isOpen\"]]]]]],[12],[1,\"\\n\\n\"],[1,\"  \"],[11,\"input\"],[16,\"placeholder\",[30,1]],[24,0,\"filter-search__input\"],[16,3,[28,[37,3],[[30,2],\"search\"],null]],[4,[38,4],[[30,0,[\"setupInput\"]]],null],[4,[38,5],[\"input\",[28,[37,6],[\"target.value\",[30,0,[\"inputHandler\"]]],null]],null],[4,[38,5],[\"focus\",[28,[37,7],[[30,0,[\"focusHandler\"]],true],null]],null],[4,[38,5],[\"blur\",[28,[37,7],[[30,0,[\"focusHandler\"]],false],null]],null],[4,[38,8],null,[[\"disabled\"],[[28,[37,9],[[30,3]],null]]]],[12],[13],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[10,0],[14,0,\"btn btn--icon\"],[14,4,\"button\"],[12],[1,\"\\n      \"],[8,[39,12],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"currentValue\"]],[[[1,\"    \"],[8,[39,13],[[24,3,\"clear_search\"]],[[\"@icon\",\"@onClick\",\"@iconSize\",\"@showFocus\"],[\"cancel\",[30,0,[\"clear\"]],\"lg\",false]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,13],null,[[\"@icon\",\"@onClick\",\"@iconSize\",\"@showFocus\"],[\"search\",[30,0,[\"focusInput\"]],\"lg\",false]],null],[1,\"\\n  \"]],[]]]],[]]],[13]],[\"@placeholder\",\"@inputName\",\"@autofocus\",\"@isWorking\"],false,[\"label\",\"bem\",\"input\",\"or\",\"did-insert\",\"on\",\"pick\",\"fn\",\"autofocus\",\"not\",\"if\",\"div\",\"loading-spinner\",\"icon-button\"]]",
    "moduleName": "forms/components/filter-search.hbs",
    "isStrictMode": false
  });
  const QUERY_MIN_LENGTH = 2;
  const SEARCH_DELAY = 800; //ms
  let FilterSearchComponent = _exports.default = (_class = class FilterSearchComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "value", _descriptor, this);
      _initializerDefineProperty(this, "placeholder", _descriptor2, this);
      _initializerDefineProperty(this, "isWorking", _descriptor3, this);
      _initializerDefineProperty(this, "onInput", _descriptor4, this);
      _initializerDefineProperty(this, "queryMinLength", _descriptor5, this);
      _initializerDefineProperty(this, "searchDelay", _descriptor6, this);
      _initializerDefineProperty(this, "collapse", _descriptor7, this);
      _initializerDefineProperty(this, "isFocused", _descriptor8, this);
      _initializerDefineProperty(this, "searchInput", _descriptor9, this);
      _initializerDefineProperty(this, "currentValue", _descriptor10, this);
    }
    // LifecycleEvents ------------------------

    // didReceiveAttrs() {
    //   super.didReceiveAttrs(...arguments);
    //   if (this.isDestroyed || !this.searchInput) return;
    //   const { value } = this;
    //   if (value !== this.currentValue) {
    //     this.searchInput.value = value || '';
    //     this.currentValue = value;
    //   }
    // }

    // Methods ------------------------

    setupInput(input) {
      const currentValue = this.value || '';
      this.searchInput = input;
      this.currentValue = currentValue;
      input.value = currentValue;
    }

    // Computed ------------------------

    get isOpen() {
      return !this.collapse || this.isFocused || !!this.currentValue;
    }

    // Actions ------------------------

    clear() {
      if (this.isDestroyed || !this.searchInput) return;
      this.searchInput.value = '';
      this.inputHandler('', true);
      this.focusInput();
    }
    focusInput() {
      if (this.isDestroyed || !this.searchInput) return;
      this.searchInput.focus();
    }
    focusHandler(focused) {
      if (this.isDestroyed || !this.searchInput) return;
      this.isFocused = focused;
    }
    inputHandler(value, noDelay) {
      this.currentValue = value;
      const isGood = typeof value === 'string' && value.length >= this.queryMinLength;
      const searchValue = isGood ? value : '';
      const delayTime = noDelay ? 0 : this.searchDelay;
      (0, _runloop.debounce)(this, 'onInput', searchValue, delayTime);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_argTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "placeholder", [_argTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isWorking", [_argTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "onInput", [_argTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "queryMinLength", [_argTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return QUERY_MIN_LENGTH;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "searchDelay", [_argTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return SEARCH_DELAY;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "collapse", [_argTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isFocused", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "searchInput", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "currentValue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focusInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focusHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inputHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "inputHandler"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FilterSearchComponent);
});