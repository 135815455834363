define("forms/components/number-input", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@manager-web/common/decorators/arg-types", "@ember/utils", "@ember/object", "prop-types", "tracked-toolbox", "@ember/template-factory"], function (_exports, _component, _component2, _service, _argTypes, _utils, _object, _propTypes, _trackedToolbox, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <InputMask
    @value={{this.value}}
    @mask={{this.mask}}
    @options={{this.maskOptions}}
    @update={{this.update}}
    @useUnmaskWorkaround={{false}}
    class="input--number text-right"
    disabled={{this.disabled}}
    autocomplete="off"
    placeholder={{this.placeholder}}
    {{autofocus disabled=this.autoFocusDisabled}}
    {{on "focus" this.focusIn}}
    ...attributes
  />
  */
  {
    "id": "amHAuMS5",
    "block": "[[[8,[39,0],[[24,0,\"input--number text-right\"],[16,\"disabled\",[30,0,[\"disabled\"]]],[24,\"autocomplete\",\"off\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]],[17,1],[4,[38,1],null,[[\"disabled\"],[[30,0,[\"autoFocusDisabled\"]]]]],[4,[38,2],[\"focus\",[30,0,[\"focusIn\"]]],null]],[[\"@value\",\"@mask\",\"@options\",\"@update\",\"@useUnmaskWorkaround\"],[[30,0,[\"value\"]],[30,0,[\"mask\"]],[30,0,[\"maskOptions\"]],[30,0,[\"update\"]],false]],null]],[\"&attrs\"],false,[\"input-mask\",\"autofocus\",\"on\"]]",
    "moduleName": "forms/components/number-input.hbs",
    "isStrictMode": false
  });
  const {
    isNaN
  } = Number;
  let NumberInputComponent = _exports.default = (_dec = (0, _service.inject)('culture'), _dec2 = (0, _argTypes.arg)(_propTypes.func.isRequired), _dec3 = (0, _argTypes.arg)(_propTypes.object), _dec4 = (0, _argTypes.arg)(_propTypes.func), _dec5 = (0, _argTypes.arg)(_propTypes.bool), _dec6 = (0, _argTypes.arg)(_propTypes.bool), _dec7 = (0, _argTypes.arg)(_propTypes.bool), _dec8 = (0, _argTypes.arg)(_propTypes.bool), _dec9 = (0, _argTypes.arg)(_propTypes.bool), _dec10 = (0, _argTypes.arg)(_propTypes.bool), _dec11 = (0, _argTypes.arg)(_propTypes.number), _dec12 = (0, _argTypes.arg)(_propTypes.number), _dec13 = (0, _argTypes.arg)(_propTypes.number), _dec14 = (0, _argTypes.arg)(_propTypes.bool), _dec15 = (0, _argTypes.arg)(_propTypes.bool), _dec16 = (0, _argTypes.arg)(_propTypes.string), (_class = class NumberInputComponent extends _component2.default {
    get placeholder() {
      return this.formatNumber(0);
    }
    // Computed ----------------------------

    get mask() {
      return this.fractionalDigits ? 'decimal' : 'integer';
    }
    get autoFocusDisabled() {
      return !this.autofocus;
    }
    get format() {
      const {
        fractionalDigits,
        digitsOptional
      } = this;
      if (fractionalDigits > 0) {
        let format = '0.';
        if (digitsOptional) format += '[';
        for (let i = 0; i < fractionalDigits; i++) {
          format += '0';
        }
        if (digitsOptional) format += ']';
        return format;
      } else {
        return '0';
      }
    }
    get value() {
      const {
        value
      } = this.args;
      const parsedValue = (0, _utils.isBlank)(value) ? null : this.formatNumber(value, this.format);
      return parsedValue;
    }
    get isInvalid() {
      return this.validation?.isInvalid || false;
    }
    get roundValue() {
      const roundValue = 10 ** this.fractionalDigits; // 10^fractionalDigits
      return roundValue;
    }

    // Lifecycle events ----------------------------

    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "cultureService", _descriptor, this);
      _initializerDefineProperty(this, "onUpdate", _descriptor2, this);
      _initializerDefineProperty(this, "validation", _descriptor3, this);
      _initializerDefineProperty(this, "blurred", _descriptor4, this);
      _initializerDefineProperty(this, "disabled", _descriptor5, this);
      _initializerDefineProperty(this, "selectAllOnFocus", _descriptor6, this);
      _initializerDefineProperty(this, "autofocus", _descriptor7, this);
      _initializerDefineProperty(this, "allowMinus", _descriptor8, this);
      _initializerDefineProperty(this, "allowBlank", _descriptor9, this);
      _initializerDefineProperty(this, "digitsOptional", _descriptor10, this);
      _initializerDefineProperty(this, "fractionalDigits", _descriptor11, this);
      _initializerDefineProperty(this, "min", _descriptor12, this);
      _initializerDefineProperty(this, "max", _descriptor13, this);
      _initializerDefineProperty(this, "showMaskOnHover", _descriptor14, this);
      _initializerDefineProperty(this, "showMaskOnFocus", _descriptor15, this);
      _defineProperty(this, "maskOptions", void 0);
      const {
        fractionalDigits,
        digitsOptional
      } = this;
      const digits = digitsOptional ? '*' : fractionalDigits;
      this.maskOptions = {
        digits,
        radixPoint: this.cultureService.decimalDelimiter,
        allowMinus: this.allowMinus,
        nullable: this.allowBlank,
        shortcuts: null,
        rightAlign: true,
        digitsOptional: !!(digitsOptional && fractionalDigits > 0),
        min: this.min,
        max: this.max,
        showMaskOnHover: this.showMaskOnHover,
        showMaskOnFocus: this.showMaskOnFocus
        // enforceDigitsOnBlur: true,
        // groupSeparator: this.cultureService.numberGroupDelimiter,
      };
    }

    // Methods ----------------------------

    formatNumber(number) {
      return this.cultureService.formatNumber(number, this.format);
    }
    unformatNumber(number) {
      if ((0, _utils.isBlank)(number)) return number;
      return this.cultureService.unformatNumber(number);
    }

    // DOM Events ----------------------------

    focusIn(e) {
      if (this.selectAllOnFocus && !this.disabled) {
        e.target.select();
      }
    }
    update(parsedValue, rawValue) {
      let num = this.unformatNumber(rawValue);
      if (this.digitsOptional && rawValue.slice(-1) === this.maskOptions.radixPoint && num === this.args.value) {
        return;
      }
      if (isNaN(num)) {
        num = 0;
      } else if ((0, _utils.isBlank)(num)) {
        num = this.allowBlank ? null : 0;
      } else {
        num = Math.round(num * this.roundValue) / this.roundValue;
      }
      this.onUpdate(num);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "cultureService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "validation", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "blurred", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectAllOnFocus", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "autofocus", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "allowMinus", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "allowBlank", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "digitsOptional", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "fractionalDigits", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "min", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "max", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "showMaskOnHover", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "showMaskOnFocus", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "placeholder", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "placeholder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mask", [_trackedToolbox.cached], Object.getOwnPropertyDescriptor(_class.prototype, "mask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autoFocusDisabled", [_trackedToolbox.cached], Object.getOwnPropertyDescriptor(_class.prototype, "autoFocusDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "format", [_trackedToolbox.cached], Object.getOwnPropertyDescriptor(_class.prototype, "format"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "value", [_trackedToolbox.cached], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusIn", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focusIn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NumberInputComponent);
});