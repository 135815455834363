define("forms/components/form-checkbox", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@manager-web/common/decorators/arg-types", "prop-types", "forms/utils/check-drag", "@ember/template-factory"], function (_exports, _component, _component2, _object, _argTypes, _propTypes, _checkDrag, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label
    ...attributes
    class={{bem
      "form-checkbox"
      this.size
      checked=this.isChecked
      unchecked=(not this.isChecked)
      rtl=this.rtl
      disabled=this.disabled
      enabled=(not this.disabled)
      inline=this.isInline
      dim=this.dim
    }}
    data-group={{this.group}}
    {{did-insert this.insertHandler}}
    {{on "mousedown" this.onMouseDown}}
  >
    <input
      type="checkbox"
      checked={{readonly this.isChecked}}
      disabled={{or this.disabled this.readOnly}}
      {{on "change" (pick "target.checked" this.onChange)}}
      name={{this.group}}
    />
    <span class="check"><IlmIcon @icon="check" /></span>
    {{#if (or this.label (has-block))}}
      <div class="form-checkbox__label label" title={{this.label}}>
        {{~#if (has-block)~}}
          {{~yield this.isChecked~}}
        {{~else~}}
          {{~this.label~}}
        {{~/if~}}
      </div>
    {{~/if~}}
  </label>
  */
  {
    "id": "zVoToeBk",
    "block": "[[[11,\"label\"],[17,1],[16,0,[28,[37,1],[\"form-checkbox\",[30,0,[\"size\"]]],[[\"checked\",\"unchecked\",\"rtl\",\"disabled\",\"enabled\",\"inline\",\"dim\"],[[30,0,[\"isChecked\"]],[28,[37,2],[[30,0,[\"isChecked\"]]],null],[30,0,[\"rtl\"]],[30,0,[\"disabled\"]],[28,[37,2],[[30,0,[\"disabled\"]]],null],[30,0,[\"isInline\"]],[30,0,[\"dim\"]]]]]],[16,\"data-group\",[30,0,[\"group\"]]],[4,[38,3],[[30,0,[\"insertHandler\"]]],null],[4,[38,4],[\"mousedown\",[30,0,[\"onMouseDown\"]]],null],[12],[1,\"\\n  \"],[11,\"input\"],[16,\"checked\",[28,[37,6],[[30,0,[\"isChecked\"]]],null]],[16,\"disabled\",[28,[37,7],[[30,0,[\"disabled\"]],[30,0,[\"readOnly\"]]],null]],[16,3,[30,0,[\"group\"]]],[24,4,\"checkbox\"],[4,[38,4],[\"change\",[28,[37,8],[\"target.checked\",[30,0,[\"onChange\"]]],null]],null],[12],[13],[1,\"\\n  \"],[10,1],[14,0,\"check\"],[12],[8,[39,10],null,[[\"@icon\"],[\"check\"]],null],[13],[1,\"\\n\"],[41,[28,[37,7],[[30,0,[\"label\"]],[48,[30,2]]],null],[[[1,\"    \"],[10,0],[14,0,\"form-checkbox__label label\"],[15,\"title\",[30,0,[\"label\"]]],[12],[41,[48,[30,2]],[[[18,2,[[30,0,[\"isChecked\"]]]]],[]],[[[1,[30,0,[\"label\"]]]],[]]],[13]],[]],null],[13]],[\"&attrs\",\"&default\"],false,[\"label\",\"bem\",\"not\",\"did-insert\",\"on\",\"input\",\"readonly\",\"or\",\"pick\",\"span\",\"ilm-icon\",\"if\",\"has-block\",\"div\",\"yield\"]]",
    "moduleName": "forms/components/form-checkbox.hbs",
    "isStrictMode": false
  });
  const MOUSE_MAIN_BUTTON = 0;
  let FormCheckboxComponent = _exports.default = (_dec = (0, _argTypes.arg)(_propTypes.bool), _dec2 = (0, _argTypes.arg)(), _dec3 = (0, _argTypes.arg)(_propTypes.bool), _dec4 = (0, _argTypes.arg)(_propTypes.string), _dec5 = (0, _argTypes.arg)(_propTypes.bool), _dec6 = (0, _argTypes.arg)(_propTypes.bool), _dec7 = (0, _argTypes.arg)(_propTypes.bool), _dec8 = (0, _argTypes.arg)(_propTypes.string), _dec9 = (0, _argTypes.arg)(_propTypes.func), (_class = class FormCheckboxComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isChecked", _descriptor, this);
      _initializerDefineProperty(this, "label", _descriptor2, this);
      _initializerDefineProperty(this, "disabled", _descriptor3, this);
      _initializerDefineProperty(this, "size", _descriptor4, this);
      _initializerDefineProperty(this, "rtl", _descriptor5, this);
      _initializerDefineProperty(this, "isInline", _descriptor6, this);
      _initializerDefineProperty(this, "dim", _descriptor7, this);
      _initializerDefineProperty(this, "group", _descriptor8, this);
      _initializerDefineProperty(this, "onChange", _descriptor9, this);
      // animate: false,
      _defineProperty(this, "element", void 0);
    }
    // Lifecycle Events ----------------------------------------

    insertHandler(element) {
      this.element = element;
    }
    onMouseDown(e) {
      if (e.button !== MOUSE_MAIN_BUTTON) return;
      const {
        group
      } = this;
      if (!group) return;
      // e.stopPropagation();
      // e.preventDefault();
      const query = `.form-checkbox[data-group="${group}"]`;
      const checkboxes = Array.from(document.querySelectorAll(query));
      // eslint-disable-next-line no-new
      new _checkDrag.default(this.element, checkboxes);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isChecked", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "size", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rtl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isInline", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "dim", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "group", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "insertHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "insertHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseDown"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormCheckboxComponent);
});