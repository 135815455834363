define("@manager-web/lock/services/user-activity", ["exports", "@ember/service", "@ember/runloop", "@ember/object", "@glimmer/tracking", "@ember/object/evented"], function (_exports, _service, _runloop, _object, _tracking, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const EVENT_USE_CAPTURE = false;
  let UserActivityService = _exports.default = (_dec = (0, _service.inject)('intl'), _dec2 = (0, _service.inject)('toaster'), (_class = class UserActivityService extends _service.default.extend(_evented.default) {
    // Computed --------------------------------

    get isTracking() {
      return this._isTracking;
    }
    get warningTimeout() {
      const {
        idleTimeout,
        warnBefore
      } = this;
      if (idleTimeout > 0 && warnBefore > 0) {
        return Math.max(idleTimeout - warnBefore, 0);
      }
      return false;
    }

    // Lifecycle events --------------------------------

    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "toaster", _descriptor2, this);
      _defineProperty(this, "idleTimeout", 600000);
      _defineProperty(this, "warnBefore", 20000);
      _defineProperty(this, "isIdle", null);
      _defineProperty(this, "defaultEvents", ['keydown', 'mousedown', 'mousemove', 'scroll']);
      _initializerDefineProperty(this, "_isTracking", _descriptor3, this);
      _defineProperty(this, "_eventsListened", []);
      _defineProperty(this, "_boundActivityEventHandler", null);
      this._boundActivityEventHandler = this._handleActivityEvent.bind(this);
    }

    // Methods --------------------------------

    _listen(eventName) {
      const eventsListened = this._eventsListened;
      if (eventsListened.indexOf(eventName) >= 0) {
        return;
      }
      eventsListened.push(eventName);
      window.addEventListener(eventName, this._boundActivityEventHandler, EVENT_USE_CAPTURE);
    }
    _startListening() {
      this.defaultEvents.forEach(eventName => this._listen(eventName));
    }
    _stopListening() {
      this._eventsListened.forEach(eventName => {
        window.removeEventListener(eventName, this._boundActivityEventHandler, EVENT_USE_CAPTURE);
      });
    }

    /**
     * Start tracking user activity
     * @param  {integer} idleTimeout Number of minutes to wait before considered idle
     */
    startTracking(idleTimeout) {
      if (idleTimeout) {
        idleTimeout *= 60000; // m to ms
        this.idleTimeout = idleTimeout;
      }
      if (this._isTracking) {
        this.stopTracking();
      }
      this._isTracking = true;
      this._startListening();
      this.resetTimer();
    }
    stopTracking() {
      this.isIdle = null;
      this._isTracking = false;
      (0, _runloop.cancel)(this._debounceRunner);
      (0, _runloop.cancel)(this._debounceWarningRunner);
    }
    _handleActivityEvent( /*event*/
    ) {
      if (this.has('userActive') && !this.isIdle) {
        this.trigger('userActive');
      }
      (0, _runloop.throttle)(this, 'resetTimer', 100);
    }
    _handleIdleEvent() {
      if (this.has('userIdle')) {
        this.trigger('userIdle');
      }
    }
    _handleAlmostIdleEvent() {
      if (this.has('userAlmostIdle')) {
        this.isAlmostIdle = true;
        this.trigger('userAlmostIdle', this.warnBefore);
      }
    }
    willDestroy() {
      this._stopListening();
    }
    resetTimer() {
      const {
        idleTimeout,
        warningTimeout
      } = this;
      const props = {
        isIdle: false,
        isAlmostIdle: false
      };
      props._debounceRunner = (0, _runloop.debounce)(this, this._handleIdleEvent, idleTimeout);
      if (warningTimeout) {
        props._debounceWarningRunner = (0, _runloop.debounce)(this, this._handleAlmostIdleEvent, warningTimeout);
      }
      (0, _object.setProperties)(this, props);
    }
    _warnAlmostIdle(time) {
      if (!this.has('userIdle')) {
        return;
      }
      const closeAfter = time;
      time /= 1000;
      const messageRaw = this.intl.t('session.locked.almost_locked_warning', {
        time
      });
      const message = this.toaster.warning({
        messageRaw,
        closeAfter
      });
      this._updateWarnMessage(message, time);
    }
    _updateWarnMessage(message, time) {
      if (!this.isAlmostIdle) {
        message.get('parentComponent').close();
        return;
      }
      if (!time || time <= 0) {
        return;
      }
      (0, _runloop.later)(this, function () {
        time--;
        const text = this.intl.t('session.locked.almost_locked_warning', {
          time
        }).toString();
        message.set('messageRaw', text);
        if (time > 0 && !message.get('parentComponent.isDestroyed')) {
          this._updateWarnMessage(message, time);
        }
      }, 1000);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_isTracking", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
});