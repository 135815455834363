define("@manager-web/grids/components/grid/card/title", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class="flex grow items-center py-4">
    {{#if @icon}}
      <div class="mr-6 flex text-2xl text-gray-500">
        <IlmIcon @icon={{@icon}} @fw={{true}} />
      </div>
    {{/if}}
    <div>
      <div class="text-xl font-semibold">{{@label}}</div>
      {{#if @description}}
        <div class="text-gray-700">{{@description}}</div>
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "7p7ayqXm",
    "block": "[[[11,0],[17,1],[24,0,\"flex grow items-center py-4\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,0],[14,0,\"mr-6 flex text-2xl text-gray-500\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@icon\",\"@fw\"],[[30,2],true]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[10,0],[14,0,\"text-xl font-semibold\"],[12],[1,[30,3]],[13],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[10,0],[14,0,\"text-gray-700\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@icon\",\"@label\",\"@description\"],false,[\"div\",\"if\",\"ilm-icon\"]]",
    "moduleName": "@manager-web/grids/components/grid/card/title.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});