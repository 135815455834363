define("@manager-web/grids/components/grid/card/attribute", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="flex break-inside-avoid-column flex-col rounded p-2 hover:bg-gray-50"
  >
    <div class="text-base text-gray-500">
      {{#if (has-block "label")}}
        {{yield to="label"}}
      {{else}}
        {{@label}}
      {{/if}}
    </div>
    {{#let (or @isEmpty (and (not (has-block)) (not @value))) as |isEmpty|}}
      <div title={{unless isEmpty (or @title @value)}}>
        {{#if isEmpty}}
          <div class="text-gray-500">{{or @emptyValue "–"}}</div>
        {{else if (has-block)}}
          <div>{{yield}}</div>
        {{else}}
          <div title={{@value}}>{{@value}}</div>
        {{/if}}
      </div>
    {{/let}}
  </div>
  */
  {
    "id": "Mb4S7l49",
    "block": "[[[10,0],[14,0,\"flex break-inside-avoid-column flex-col rounded p-2 hover:bg-gray-50\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"text-base text-gray-500\"],[12],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"      \"],[18,7,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,1]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[44,[[28,[37,5],[[30,2],[28,[37,6],[[28,[37,7],[[48,[30,8]]],null],[28,[37,7],[[30,3]],null]],null]],null]],[[[1,\"    \"],[10,0],[15,\"title\",[52,[51,[30,4]],[28,[37,5],[[30,5],[30,3]],null]]],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[10,0],[14,0,\"text-gray-500\"],[12],[1,[28,[35,5],[[30,6],\"–\"],null]],[13],[1,\"\\n\"]],[]],[[[41,[48,[30,8]],[[[1,\"        \"],[10,0],[12],[18,8,null],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[15,\"title\",[30,3]],[12],[1,[30,3]],[13],[1,\"\\n      \"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[4]]],[13]],[\"@label\",\"@isEmpty\",\"@value\",\"isEmpty\",\"@title\",\"@emptyValue\",\"&label\",\"&default\"],false,[\"div\",\"if\",\"has-block\",\"yield\",\"let\",\"or\",\"and\",\"not\",\"unless\"]]",
    "moduleName": "@manager-web/grids/components/grid/card/attribute.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});