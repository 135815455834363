define("forms/components/form-toggle", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label
    class={{class-themer
      "form-toggle"
      disabled=@disabled
      readonly=@readOnly
      labelFirst=@labelFirst
    }}
    ...attributes
  >
    <input
      type="checkbox"
      checked={{readonly @isChecked}}
      disabled={{or @disabled @readOnly}}
      {{on "change" (pick "target.checked" @onChange)}}
    />
    <span class="form-toggle__cb"></span>
    {{#if (or (has-block) @label)}}
      <span class="form-toggle__label">
        {{#if (has-block)}}{{yield}}{{else}}{{@label}}{{/if}}
      </span>
    {{/if}}
  
    {{~#if @tooltip}}
      <FloatinguiTooltip @placement={{or @tooltipPosition "top"}}>
        {{nl2br @tooltip}}
      </FloatinguiTooltip>
    {{/if}}
  </label>
  */
  {
    "id": "H7x7jUac",
    "block": "[[[11,\"label\"],[16,0,[28,[37,1],[\"form-toggle\"],[[\"disabled\",\"readonly\",\"labelFirst\"],[[30,1],[30,2],[30,3]]]]],[17,4],[12],[1,\"\\n  \"],[11,\"input\"],[16,\"checked\",[28,[37,3],[[30,5]],null]],[16,\"disabled\",[28,[37,4],[[30,1],[30,2]],null]],[24,4,\"checkbox\"],[4,[38,5],[\"change\",[28,[37,6],[\"target.checked\",[30,6]],null]],null],[12],[13],[1,\"\\n  \"],[10,1],[14,0,\"form-toggle__cb\"],[12],[13],[1,\"\\n\"],[41,[28,[37,4],[[48,[30,10]],[30,7]],null],[[[1,\"    \"],[10,1],[14,0,\"form-toggle__label\"],[12],[1,\"\\n      \"],[41,[48,[30,10]],[[[18,10,null]],[]],[[[1,[30,7]]],[]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,8],[[[1,\"    \"],[8,[39,11],null,[[\"@placement\"],[[28,[37,4],[[30,9],\"top\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,12],[[30,8]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[\"@disabled\",\"@readOnly\",\"@labelFirst\",\"&attrs\",\"@isChecked\",\"@onChange\",\"@label\",\"@tooltip\",\"@tooltipPosition\",\"&default\"],false,[\"label\",\"class-themer\",\"input\",\"readonly\",\"or\",\"on\",\"pick\",\"span\",\"if\",\"has-block\",\"yield\",\"floatingui-tooltip\",\"nl2br\"]]",
    "moduleName": "forms/components/form-toggle.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});